<template>
  <div>
    <validation-observer
      ref='form'
      v-slot='{invalid}'
    >

      <Spinner :is-data-loaded='isDataLoaded' />

      <b-card v-if='isDataLoaded && !is_data_valid'>
        <validation-observer
          ref='form1'
          v-slot='{invalid}'
        >

          <b-form
            novalidate
            @submit.prevent='validateLicense'
          >
            <b-row class='align-items-end'>

              <b-col
                cols='12'
                class='mb-md-0 mb-2'
              >
                <ValidationProvider
                  v-slot='{ errors }'
                  vid='ad_license_number'
                  :name="$t('authorization_number')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('authorization_number')"
                    label-for='ad_license_number'
                  >
                    <b-form-input
                      id='ad_license_number'
                      type='number'
                      v-model='ad_license_number'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols='12'
                class='mb-md-0 mb-2'
                v-if='id_type'
              >
                <ValidationProvider
                  v-slot='{ errors }'
                  vid='authorization_number'
                  :name="id_type === 1 ? $t('national_identification_number') : $t('commercial_record')"
                  rules='required'
                >
                  <b-form-group
                    :label="id_type === 1 ? $t('national_identification_number') : $t('commercial_record')"
                    label-for='national_identification_number'
                  >
                    <b-form-input
                      id='national_id_number'
                      type='number'
                      v-model='authorization_number'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>

              </b-col>


              <!-- submit and reset -->
              <b-col cols='12 text-right'>
                <LoadingButton :loader='is_validator_loading' />
                <b-button
                  type='reset'
                  variant='outline-secondary'
                >
                  {{ $t('labels.reset') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>

      <b-form
        v-if='isDataLoaded && is_data_valid'
        novalidate
        @submit.prevent='onSubmit'
      >
        <b-card>

          <!--Tabs information-->
          <ul class='custom-tabs-form'>

            <li
              v-if='is_authorized_page'
              :class="{'active' : selectedTab === 'license_list'}"
              @click="selectTab('license_list')"
            >
              <span>{{ $t('license_list') }}</span>
            </li>

            <li
              :class="{'active' : selectedTab === 'advertisement_details','danger': advertisement_details_errors_length > 0}"
              @click="selectTab('advertisement_details')"
            >
              <span>{{ $t('advertisement_details') }}</span> <span
              v-if='advertisement_details_errors_length > 0'
              class='num-error-style'
            >{{ advertisement_details_errors_length }}</span>
            </li>
            <li
              :class="{'active' : selectedTab === 'property_information','danger': property_information_errors_length > 0}"
              @click="selectTab('property_information')"
            >
              <span>{{ $t('property_information') }}</span> <span
              v-if='property_information_errors_length > 0'
              class='num-error-style'
            >{{ property_information_errors_length }}</span>
            </li>
            <li
              :class="{'active' : selectedTab === 'attachments','danger': attachments_errors_length > 0}"
              @click="selectTab('attachments')"
            >
              <span>{{ $t('attachments') }}</span> <span
              v-if='attachments_errors_length > 0'
              class='num-error-style'
            >{{ attachments_errors_length }}</span>
            </li>

            <li
              :class="{'active' : selectedTab === 'geographical_location','danger': geographical_location_errors_length > 0}"
              @click="selectTab('geographical_location')"
            >
              <span>{{ $t('geographical_location') }}</span> <span
              v-if='geographical_location_errors_length > 0'
              class='num-error-style'
            >{{ geographical_location_errors_length }}</span>
            </li>

          </ul>

          <!--Map-->
          <div class='position-relative'>
            <div v-if='selectedTab !== "geographical_location"' class='hide-map-layer'></div>

            <div :class="{'hide-map': selectedTab !== 'geographical_location' }">
              <label
                style='font-weight: 500;font-size: 16px'
                class='mb-1'
              >
                {{ $t('Choose the address from the map or fill in the fields below') }}
              </label>

              <div class='map-app' :class="{'hide-map': selectedTab !== 'geographical_location' }">
                <div id='map-layout'>
                  <Map @childDataUpdated='handleMapData' :modelValue='map_coordinate'
                       :isMarkerDisabled='is_marker_disabled' />
                </div>
              </div>
            </div>

          </div>

          <!--Property information Tab-->
          <div class='tab-layer' v-show="selectedTab === 'license_list'">
            <b-row>
              <b-col
                cols='12'
                md='6'
                class='mt-2'
                v-for='(el, key, index) in info_fields_label'
                :key='index'
              >
                <div>
                  <div class='d-flex align-items-center'>
                    <h4>{{ key }} :</h4>
                    <h4 class='mx-1'>{{ Array.isArray(el) ? el.join(', ') : el }}</h4>
                  </div>
                </div>
              </b-col>

              <b-col
                cols='12'
                class='d-flex justify-content-center mt-2 gap-20'
              >
                <b-button
                  variant='outline-secondary'
                  @click="selectTab('advertisement_details')"
                >
                  {{ $t('next') }}
                </b-button>
              </b-col>
            </b-row>
          </div>

          <!--General information Tab-->
          <div class='tab-layer' v-show="selectedTab === 'advertisement_details'">
            <b-row>

              <b-col cols='12' md='6'>
                <NewCustomerSelector :isEdit='isEdit' @update:customer_id='handleCustomerIdsUpdate' />
              </b-col>

              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
              >
                <b-form-group
                  :label="$t('creator_name')"
                  label-for='creator_name'
                  v-if='isEdit && form.advertiser_name'
                >
                  <b-form-input
                    style='direction: ltr; text-align: right;display: flex;justify-content: end'
                    id='creator_name'
                    type='text'
                    :value='form.advertiser_name'
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
              >
                <b-form-group
                  :label="$t('creator_mobile')"
                  label-for='creator_mobile'
                  v-if='isEdit'
                >
                  <b-form-input
                    style='direction: ltr; text-align: right;display: flex;justify-content: end'
                    id='creator_mobile'
                    type='text'
                    :value='form.advertiser_phone'
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
              >
                <b-form-group
                  :label="$t('updated_by')"
                  label-for='updated_by'
                  v-if='isEdit'
                >
                  <b-form-input
                    style='direction: ltr; text-align: right;display: flex;justify-content: end'
                    id='updated_by'
                    type='text'
                    :value='form.updated_by'
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
              >
                <b-form-group
                  :label="$t('updated_at')"
                  label-for='updated_at'
                  v-if='isEdit'
                >
                  <b-form-input
                    style='direction: ltr; text-align: right;display: flex;justify-content: end'
                    id='updated_at'
                    type='text'
                    :value='form.updated_at'
                    disabled
                  />
                </b-form-group>
              </b-col>


              <b-col
                cols='12'
                md='6'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='the_sub_type_of_ad'
                  :name="$t('the_sub_type_of_ad')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('the_sub_type_of_ad')"
                  >
                    <v-select
                      v-model='form.the_sub_type_of_ad'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='the_sub_type_of_ads'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      :disabled='checkKeyIfDisabled("the_sub_type_of_ad")'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>


              <b-col
                cols='12'
                md='6'
                v-if='usages.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='usage_id'
                  :name="$t('usage_id')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('usage_id')"
                  >
                    <v-select
                      v-model='form.usage_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='usages'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      @input="inputChange('usage_id')"
                      :disabled='checkKeyIfDisabled("usage_id")'

                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols='12'
                md='6'
                v-if='types.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='type_id'
                  :name="$t('type_id')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('type_id')"
                  >
                    <v-select
                      v-model='form.type_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='types'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      @input="inputChange('type_id')"
                      :disabled='checkKeyIfDisabled("type_id")'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                v-if='sub_types.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='sub_type_id'
                  :name="$t('sub_type_id')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('sub_type_id')"
                  >
                    <v-select
                      v-model='form.sub_type_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='sub_types'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      @input="inputChange('sub_type_id')"
                      :disabled='checkKeyIfDisabled("sub_type_id")'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
              >
                <ValidationProvider
                  v-slot='{ errors }'
                  vid='land_number'
                  :name="$t('land_number')"
                >
                  <b-form-group
                    :label="$t('land_number')"
                    label-for='land_number'
                  >
                    <b-form-input
                      id='land_number'
                      type='number'
                      v-model='form.land_number'
                      placeholder='XXXXXXXXXXXX'
                      :disabled='checkKeyIfDisabled("land_number")'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols='12'
                md='6'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='member_type'
                  :name="$t('member_type')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('member_type')"
                  >
                    <v-select
                      v-model='form.member_type'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='member_types'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='region_id'
                  :name="$t('region')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('region')"
                  >
                    <v-select
                      v-model='form.region_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='regions'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"

                      @input="inputChange('region_id')"
                      :disabled='checkKeyIfDisabled("region_id")'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>


              <b-col
                cols='12'
                md='6'
                v-if='cities.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='city_id'
                  :name="$t('city')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('city')"
                  >
                    <v-select
                      v-model='form.city_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='cities'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"

                      @input="inputChange('city_id')"
                      :disabled='checkKeyIfDisabled("city_id")'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                v-if='districts.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='district_id'
                  :name="$t('district')"
                >

                  <b-form-group
                    :label="$t('district')"
                  >
                    <v-select
                      v-model='form.district_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='districts'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      :disabled='checkKeyIfDisabled("district_id")'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                class='mt-2'
              >
                <ValidationProvider
                  v-slot='{ errors }'
                  vid='map_icon'
                  :name="$t('map_icon')"
                >
                  <ImagePreview
                    v-model='map_icon'
                    :label="$t('map_icon')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </ValidationProvider>
                <hr class='my-1'>
              </b-col>


              <b-col
                cols='12'
                class='d-flex justify-content-center mt-2 gap-20'
              >

                <b-button
                  v-if='is_authorized_page'
                  variant='outline-secondary'
                  @click="selectTab('license_list')"
                >
                  {{ $t('previous') }}
                </b-button>

                <b-button
                  variant='outline-secondary'
                  @click="selectTab('property_information')"
                >
                  {{ $t('next') }}
                </b-button>
              </b-col>
            </b-row>
          </div>


          <!--Property information Tab-->
          <div class='tab-layer' v-show="selectedTab === 'property_information'">
            <b-row>
              <b-col
                cols='12'
                class='mb-1'
              >
                <validation-provider
                  v-slot='{ errors }'
                  :name="$t('price')"
                  vid='price_type'
                  rules='required'
                >
                  <b-form-group
                    :label="$t('price')"
                  >
                    <div
                      class='border rounded-sm p-1 d-flex custom-gap-40'
                    >
                      <b-form-radio
                        v-model='form.price_type'
                        name='price_type'
                        :value='1'
                        @input="inputChange('price_type')"
                        :disabled='checkKeyIfDisabled("price")'
                      >
                        {{ $t('specific_price') }}
                      </b-form-radio>
                      <b-form-radio
                        v-model='form.price_type'
                        name='price_type'
                        :value='2'
                        @input="inputChange('price_type')"
                        :disabled='checkKeyIfDisabled("price")'
                      >
                        {{ $t('last_bid') }}
                      </b-form-radio>
                    </div>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                md='5'
                cols='11'
                v-if='form.price_type ==1'
              >
                <validation-provider
                  v-slot='{ errors }'
                  :name="$t('price')"
                  vid='price'
                  :rules="form.price_type ===1 ? 'required':''"
                >
                  <b-form-group
                  >
                    <b-form-input
                      v-model='form.price'
                      autocomplete='off'
                      :placeholder="$t('price')"
                      type='number'
                      :disabled='checkKeyIfDisabled("price")'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>

                </validation-provider>
              </b-col>
              <b-col
                cols='1'
                class='d-flex justify-content-start align-items-center p-0'
                v-if='form.price_type ==1'
              >
                <b-card-text>
                  {{ $t('labels.price_riyal') }}
                </b-card-text>
              </b-col>

              <b-col
                cols='6'
                class='mb-1'
                v-if='form.price_type ==1'

              >

                <b-form-checkbox
                  v-model='form.is_negotiable'
                  checked='1'
                  value='1'
                  unchecked-value='0'
                  name='check-button'
                  switch
                  :disabled='checkKeyIfDisabled("price")'

                >
                  {{ $t('is_negotiable') }}

                </b-form-checkbox>
              </b-col>

              <b-col
                md='5'
                cols='11'
                v-if='form.price_type ==2'
              >
                <validation-provider
                  v-slot='{ errors }'
                  :name="$t('price')"
                  vid='price'
                >
                  <b-form-group
                  >
                    <b-form-input
                      v-model='form.price'
                      autocomplete='off'
                      :placeholder="$t('price')"
                      type='number'
                      :disabled='checkKeyIfDisabled("price")'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>

                </validation-provider>
              </b-col>
              <b-col
                cols='1'
                class='d-flex justify-content-start align-items-center p-0'
                v-if='form.price_type ==2'
              >
                <b-card-text>
                  {{ $t('labels.price_riyal') }}
                </b-card-text>
              </b-col>

              <b-col
                cols='6'
                class='mb-1'
                v-if='form.price_type ==2'

              >

                <b-form-checkbox
                  v-model='form.no_bid_received'
                  checked='1'
                  value='1'
                  unchecked-value='0'
                  name='check-button'
                  switch
                  :disabled='checkKeyIfDisabled("price")'

                >
                  {{ $t('no_bid_received') }}

                </b-form-checkbox>
              </b-col>


              <b-col
                cols='12'
                md='6'
                v-for='(column, index) in columns_real_estate_values'
                :key='index'
              >

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="(column.is_required ? 'required' : '') + (column.type == 'number' ? (column.is_required ? '|' : '') + 'numeric' : '')"
                  v-if='column.type =="number" || column.type =="text"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <b-form-input
                      v-model='form.info_fields[column.key]'
                      autocomplete='off'
                      :type='column.type === "text" ? "text" :"number"'
                      :placeholder='column.placeholder'
                      style='height: 50px'
                      :disabled='checkKeyIfDisabled(column.key,true)'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="column.is_required ? 'required' : ''"
                  v-if='column.type =="between"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <div class='d-flex'>
                      <b-form-input
                        v-model='form.info_fields["from_"+column.key]'
                        autocomplete='off'
                        type='number'
                        :placeholder='column.placeholder_to'
                        style='height: 50px'
                        :disabled='checkKeyIfDisabled(column.key,true)'
                      />
                      <b-form-input
                        v-model='form.info_fields["to_"+column.key]'
                        autocomplete='off'
                        type='number'
                        :placeholder='column.placeholder_from'
                        style='height: 50px;margin-right: 8px'
                        :disabled='checkKeyIfDisabled(column.key,true)'
                      />
                    </div>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>


                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="column.is_required ? 'required' : ''"
                  v-if='column.type =="text_area"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <b-form-textarea
                      v-model='form.info_fields[column.key]'
                      :placeholder='column.placeholder'
                      autocomplete='off'
                      rows='5'
                      :disabled='checkKeyIfDisabled(column.key,true)'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :vid='column.key'
                  :name='column.label'
                  :rules='column.is_required ?"required":""'
                  v-else-if='column.type =="list"'
                >

                  <b-form-group
                    :label='column.label'
                  >
                    <v-select
                      v-model='form.info_fields[column.key]'
                      :dir='$store.state.appConfig.layout.direction'
                      label='label'
                      :options='column.options'
                      :reduce='item => item.value'
                      :placeholder="$t('select')"
                      :disabled='checkKeyIfDisabled(column.key,true)'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :vid='column.key'
                  :name='column.label'
                  :rules='column.is_required ?"required":""'
                  v-else-if='column.type =="checkbox"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <v-select
                      v-model='form.info_fields[column.key]'
                      :dir='$store.state.appConfig.layout.direction'
                      label='label'
                      :options='column.options'
                      :reduce='item => item.value'
                      :placeholder="$t('select')"
                      multiple
                      :disabled='checkKeyIfDisabled(column.key,true)'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!--                :rules="column.is_required ? 'required':''"-->

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="'required'"

                  v-else-if='column.type =="radio"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <div
                      class='border rounded-sm p-1 d-flex custom-gap-40'
                    >

                      <b-form-radio
                        v-for='(option, key) in column.options'
                        :key='key'
                        v-model='form.info_fields[column.key]'
                        :name='column.key'
                        :value='option.value'
                        :disabled='checkKeyIfDisabled(column.key,true)'
                      >
                        {{ option.label }}
                      </b-form-radio>

                    </div>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="column.is_required ? 'required' : ''"
                  v-if='column.type =="range"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <vue-slider
                      :process-style='{background:"#b27354"}'
                      :tooltip-formatter='formatter2'
                      :enable-cross='false'
                      v-model='form.info_fields[column.key]'
                      :min='column.min' :max='column.max'
                      :disabled='checkKeyIfDisabled(column.key,true)'
                    />


                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

              </b-col>

              <b-col
                cols='12'
                md='6'
                v-if='amenities.length > 0'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='amenities'
                  :name="$t('amenities_form')"
                >
                  <b-form-group
                    :label="$t('amenities_form')"
                  >

                    <v-select
                      v-model='form.amenities'
                      :dir='$store.state.appConfig.layout.direction'
                      label='title'
                      multiple
                      :options='amenities'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      :disabled='checkKeyIfDisabled("amenities")'
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                class='mb-1'
              >
                <b-card-text style='margin: 5px'>
                  {{ $t('labels.is_rented') }}
                </b-card-text>
                <b-form-checkbox
                  v-model='form.is_rented'
                  checked='1'
                  value='1'
                  unchecked-value='0'
                  name='check-button'
                  switch
                  @input="inputChange('is_rented')"
                  :disabled='checkKeyIfDisabled("price")'
                />
              </b-col>

              <b-col
                md='6'
                cols='12'
                v-if='form.is_rented ==1'
              >
                <!--                :rules="form.is_rented ==1 ? 'required|numeric':'numeric'"-->

                <validation-provider
                  v-slot='{ errors }'
                  :name="$t('labels.current_rent_price')"
                  vid='current_rent_price'

                >
                  <b-form-group
                    :label="$t('labels.current_rent_price')"
                  >
                    <b-form-input
                      v-model='form.current_rent_price'
                      :placeholder="$t('labels.current_rent_price')"
                      autocomplete='off'
                      type='number'
                      :disabled='checkKeyIfDisabled("price")'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md='5'
                cols='11'
                v-if='form.is_rented ==1'
              >
                <!--                :rules="form.is_rented ==1 ? 'required|numeric':'numeric'"-->

                <validation-provider
                  v-slot='{ errors }'
                  :name="$t('labels.remaining_month')"
                  vid='remaining_month'
                >
                  <b-form-group
                    :label="$t('labels.remaining_month')"
                  >
                    <b-form-input
                      v-model='form.remaining_month'
                      :placeholder="$t('labels.remaining_month')"
                      autocomplete='off'
                      type='number'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>

                </validation-provider>
              </b-col>
              <b-col
                cols='1'
                class='d-flex justify-content-start align-items-center p-0'
                v-if='form.is_rented ==1'
              >
                <b-card-text style='margin: 5px'>
                  {{ $t('labels.month') }}
                </b-card-text>
              </b-col>
              <b-col
                cols='12'
                class='d-flex justify-content-center mt-2 gap-20'
              >
                <b-button
                  variant='outline-secondary'
                  @click="selectTab('advertisement_details')"
                >
                  {{ $t('previous') }}
                </b-button>
                <b-button
                  variant='outline-secondary'
                  @click="selectTab('attachments')"
                >
                  {{ $t('next') }}
                </b-button>
              </b-col>
            </b-row>
          </div>

          <!--Attachments-->
          <div class='tab-layer' v-show="selectedTab === 'attachments'">
            <b-row v-if='!isUploaderLoaded'>
              <b-col
                cols='12'
                md='6'
                class='mt-2'
              >

                <validation-provider
                  v-slot='{ errors }'
                  name='images'
                  vid='images'
                >
                  <div>
                    <div class='d-flex justify-content-between align-items-center'>
                      <h4>{{ $t('attach_photos') }}</h4>

                      <b-form-file
                        id='images'
                        class='hidden'
                        multiple
                        accept='image/*'
                        @change="uploader($event,'images')"
                      />
                      <label
                        for='images'
                        class='btn btn-primary'
                      >
                        {{ $t('attach') }}
                      </label>
                    </div>
                    <ul
                      v-if='images_arr.length'
                      class='list-uploader'
                    >
                      <li
                        v-for='(file,index) in images_arr'
                        :key='file.name'
                      >
                        <div class='image-box'>
                          <div class='img-container'>
                            <img
                              :src='file.url'
                              @error='(event) => handleImageError(event, index)'
                              alt='Image'
                            >
                          </div>
                        </div>
                        <button
                          type='button'
                          class='btn bg-danger uploader-btn'
                          @click="handleDeleteImage(index,'images_arr')"
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='#ffffff'
                            class='bi bi-trash3'
                            viewBox='0 0 16 16'
                          >
                            <path
                              d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z'
                            />
                          </svg>
                        </button>
                        <button
                          type='button'
                          class='btn bg-info uploader-btn'
                          @click.prevent='downloadFile(file,"image")'
                        >
                          <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black'
                               class='bi bi-arrow-bar-down' viewBox='0 0 16 16'>
                            <path fill-rule='evenodd'
                                  d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5M8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6' />
                          </svg>
                        </button>
                        <b-form-radio
                          v-model='form.main_image'
                          :name='file.original_name'
                          :value='file.original_name'
                        >
                        </b-form-radio>
                      </li>
                    </ul>
                    <h5
                      v-if='!images_arr.length'
                      class='no-files'
                    >
                      {{ $t('No files attached') }}
                    </h5>
                    <small
                      v-if='!images_arr.length'
                      class='text-danger'
                    >{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                class='mt-2'
              >
                <validation-provider
                  v-slot='{ errors }'
                  name='video'
                  vid='video'
                >
                  <div>
                    <div class='d-flex justify-content-between align-items-center'>
                      <h4>{{ $t('attach_video') }}</h4>
                      <b-form-file
                        id='video'
                        class='hidden'
                        multiple
                        accept='video/*'
                        @change="uploader($event,'video')"
                      />
                      <label
                        for='video'
                        class='btn btn-primary'
                      >
                        {{ $t('attach') }}
                      </label>
                    </div>
                    <ul
                      v-if='video_arr.length'
                      class='list-uploader'
                      v-for='(file,index) in video_arr'
                      :key='file.name'
                    >
                      <li
                      >
                        <div class='image-box'>
                          <div>
                            <video width='340' height='120' controls>
                              <source :src='file.url' type='video/mp4'>
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                        <button
                          type='button'
                          class='btn bg-danger uploader-btn'
                          @click="handleDeleteImage(index,'video_arr')"
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='#ffffff'
                            class='bi bi-trash3'
                            viewBox='0 0 16 16'
                          >
                            <path
                              d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z'
                            />
                          </svg>
                        </button>
                        <button
                          type='button'
                          class='btn bg-info uploader-btn'
                          @click.prevent='downloadFile(file,"video")'
                        >
                          <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black'
                               class='bi bi-arrow-bar-down' viewBox='0 0 16 16'>
                            <path fill-rule='evenodd'
                                  d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5M8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6' />
                          </svg>
                        </button>

                      </li>
                    </ul>
                    <h5
                      v-if='!video_arr.length'
                      class='no-files'
                    >
                      {{ $t('No files attached') }}
                    </h5>
                    <small
                      v-if='!video_arr.length'
                      class='text-danger'
                    >{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                class='mt-2'
              >
                <validation-provider
                  v-slot='{ errors }'
                  name='documents'
                  vid='documents'
                >
                  <div>
                    <div class='d-flex justify-content-between align-items-center'>
                      <h4>{{ $t('documents') }}</h4>
                      <b-form-file
                        id='documents'
                        class='hidden'
                        multiple
                        accept='image/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/csv'
                        @change="uploader($event,'documents')"
                      />
                      <label
                        for='documents'
                        class='btn btn-primary'
                      >
                        {{ $t('attach') }}
                      </label>
                    </div>
                    <ul
                      v-if='files_documents.length'
                      class='list-uploader'
                    >
                      <li
                        v-for='(file,index) in files_documents'
                        :key='file.name'
                      >
                        <div class='image-box'>
                          <div class='img-container'>
                            <img
                              src='@/assets/images/file.svg'
                              alt='Image'
                              class='pdf'
                              style='width: 90%'
                            >

                          </div>
                        </div>
                        <button
                          type='button'
                          class='btn bg-danger uploader-btn'
                          @click="handleDeleteImage(index,'files_documents')"
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='#ffffff'
                            class='bi bi-trash3'
                            viewBox='0 0 16 16'
                          >
                            <path
                              d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z'
                            />
                          </svg>
                        </button>
                        <button
                          type='button'
                          class='btn bg-info uploader-btn'
                          @click.prevent='downloadFile(file,"document")'
                        >
                          <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black'
                               class='bi bi-arrow-bar-down' viewBox='0 0 16 16'>
                            <path fill-rule='evenodd'
                                  d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5M8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6' />
                          </svg>
                        </button>

                      </li>
                    </ul>
                    <h5
                      v-if='!files_documents.length'
                      class='no-files'
                    >
                      {{ $t('No files attached') }}
                    </h5>
                    <small
                      v-if='!files_documents.length'
                      class='text-danger'
                    >{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-col>


              <b-col
                cols='12'
                class='d-flex justify-content-center mt-2 gap-20'
              >
                <b-button
                  variant='outline-secondary'
                  @click="selectTab('property_information')"
                >
                  {{ $t('previous') }}
                </b-button>
                <b-button
                  variant='outline-secondary'
                  @click="selectTab('geographical_location')"
                >
                  {{ $t('next') }}
                </b-button>
              </b-col>
            </b-row>
            <div class='text-center text-success my-2' v-else>
              <span class='mx-2'>{{ $t('file_processing') }}</span>
              <b-spinner class='align-middle' />
            </div>
          </div>

          <!--Geographical location Tab-->
          <div class='tab-layer' v-show="selectedTab === 'geographical_location'">
            <b-row class='mt-2'>


              <b-col
                cols='12'
              >
                <button v-if='form.longitude && form.latitude' type='button'
                        class='bg-primary btn-primary rounded h-100'
                        @click='redirectToGoogleMaps(form.latitude, form.longitude)'>{{ $t('google_to_map') }}
                </button>
              </b-col>

              <b-col
                cols='12'
                class='d-flex justify-content-center mt-2 gap-20'
              >
                <b-button
                  variant='outline-secondary'
                  @click="selectTab('attachments')"
                >
                  {{ $t('previous') }}
                </b-button>
                <LoadingButton />
              </b-col>
            </b-row>
          </div>


        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import { BFormRadio } from 'bootstrap-vue'
import { websiteMainUrl } from '@/services/url.service'

import formMixin from '@/mixins/formMixin'
import 'leaflet/dist/leaflet.css'

import Cleave from 'vue-cleave-component'

import Map from '@/components/Map.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Spinner from '@/components/Spinner.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import NewCustomerSelector from '@/components/NewCustomerSelector.vue'

export default {
  components: {
    NewCustomerSelector,
    VueSlider,
    Spinner,
    BFormRadio,
    Cleave,
    Map,
  },
  mixins: [formMixin],
  data() {
    return {
      imagePreviews: [],
      cleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      is_marker_disabled: false,
      id_type: null,
      ad_license_number: null,
      authorization_number: null,
      selectedTab: 'advertisement_details',
      websiteUrl: websiteMainUrl(),
      disabled_fields: [],
      the_sub_type_of_ads: [],
      member_types: [],
      usages: [],
      types: [],
      sub_types: [],
      amenities: [],
      map_coordinate: [],
      cities: [],
      regions: [],
      districts: [],
      images_arr: [],
      video_arr: [],

      files_documents: [],
      info_fields_label: {},

      is_validator_loading: false,
      map_icon: null,
      form: {

        //tab 1
        ad_type: 1,
        new_creator_id: null,
        advertiser_details: null,
        // Tab 2
        usage_id: null,
        type_id: null,
        sub_type_id: null,
        the_sub_type_of_ad: null,
        is_rented: 0,
        current_rent_price: null,
        remaining_month: null,
        price_type: 1,
        price: null,
        info_fields: {},
        is_negotiable: 0,
        no_bid_received: 1,
        land_number: null,
        member_type: null,
        // Tab 3
        images: [],
        video: null,
        documents: [],
        main_image: null,
        map_icon: null,
        // Tab 4
        region_id: null,
        city_id: null,
        district_id: null,
        latitude: null,
        longitude: null,


        // Tab 4


        amenities: [],


      },

      is_authorized_page: false,
      is_data_valid: true,

      //errors tab 1

      advertisement_details_errors_length: 0,
      advertisement_details_errors: [
        'the_sub_type_of_ad',
        'usage_id',
        'type_id',
        'sub_type_id',
        'region_id',
        'city_id',
        'district_id',
        'land_number',
        'member_type',
      ],

      //errors tab 2
      property_information_errors_length: 0,
      property_information_errors: [
        'price',
        'price_type',
        'is_negotiable',
        'is_rented',
        'current_rent_price',
        'remaining_month',
        'the_sub_type_of_ad',
        'no_bid_received',

        'amenities',
      ],

      original_property_information_errors: [
        'price',
        'price_type',
        'is_negotiable',
        'is_rented',
        'current_rent_price',
        'remaining_month',
        'the_sub_type_of_ad',
        'no_bid_received',

        'amenities',
      ],

      //errors tab 4
      geographical_location_errors_length: 0,
      geographical_location_errors: [],

      //errors tab 5

      attachments_errors_length: 0,

      attachments_errors: [],
      images_files: [],
      video_files: [],


      documents_files: [],


      columns_real_estate_values: [],

      isDataLoaded: false,
      isUploaderLoaded: false,
    }
  },

  watch: {
    map_icon(val) {
      if (val) {
        this.form.map_icon = val
      } else {
        this.form.map_icon = 'delete'
      }
    },
    ad_license_number: {
      handler(val = null) {
        if (val) {
          if (val.startsWith('71')) {
            this.id_type = 1
          } else if (val.startsWith('72')) {
            this.id_type = 2
          } else {
            this.id_type = null
            this.authorization_number = null
          }
        }
      },
    },
    images_arr: {
      handler(val = null) {
        if (val) {
          this.watchFilesChanged('images', val)
        }
      },
    },
    video_arr: {
      handler(val = null) {
        if (val) {
          this.watchFilesChanged('video', val)
        }
      },
    },

    files_documents: {
      handler(val = null) {
        if (val) {
          this.watchFilesChanged('documents', val)
        }
      },
    },


    formErrors: {
      deep: true,
      handler(val = null) {
        if (val) {
          this.getErrorTabLength(val, this.advertisement_details_errors, 'advertisement_details_errors_length')
          this.getErrorTabLength(val, this.geographical_location_errors, 'geographical_location_errors_length')

          this.getErrorTabLength(val, this.property_information_errors, 'property_information_errors_length')

          this.getErrorTabLength(val, this.attachments_errors, 'attachments_errors_length')
        }
      },
    },
    form: {
      deep: true,
      handler() {
        setTimeout(() => {
          const formErrors = this.$refs.form.errors
          this.formErrors = formErrors
          this.getErrorTabLength(formErrors, this.advertisement_details_errors, 'advertisement_details_errors_length')

          this.getErrorTabLength(formErrors, this.geographical_location_errors, 'geographical_location_errors_length')
          this.getErrorTabLength(formErrors, this.property_information_errors, 'property_information_errors_length')


          this.getErrorTabLength(formErrors, this.attachments_errors, 'attachments_errors_length')

        }, 500)
      },
    },
  },
  created() {

    if (this.$route.path === '/real-estate-licensed/add') {
      this.is_authorized_page = true
      this.is_data_valid = false
    }


    (async () => {
      await this.getMemberTypes()
    })();

    (async () => {
      await this.getRegion()
    })();

    (async () => {
      await this.getLists()
    })();

    (async () => {
      await this.getUsages()
    })()

    if (this.isEdit) {
      this.loadData()
    } else {
      setTimeout(() => {
        this.isDataLoaded = true
      }, 2000)
    }

  },


  methods: {
    redirectToGoogleMaps(lat, lng) {
      const url = `https://www.google.com/maps?q=${lat},${lng}`
      // Open the URL in a new tab
      window.open(url, '_blank')
    },
    handleCustomerIdsUpdate(customerId) {
      if (customerId) {
        this.form.new_creator_id = customerId
      } else {
        this.form.new_creator_id = null
      }
    },
    checkKeyIfDisabled(key, is_dynamic = false) {
      if (this.form?.ad_type != 2) {
        return false
      }

      if (this.disabled_fields.includes(key)) {
        if (is_dynamic) {
          if (this.form['info_fields'][key] ?? false) {
            return true
          }
        }
        if (this.form[key] ?? false) {
          return true
        }
        return false

      } else {
        return false
      }
    },
    async downloadFile(file, name) {

      try {
        const response = name == 'document' ? await fetch(file) : await fetch(file.url)


        const blob = await response.blob()

        // Extract filename from URL
        const filename = name + '-' + this.generateFileName(5)

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

      } catch (error) {
        console.error('Error downloading the file:', error)
      }
    },

    generateFileName(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let result = ''
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },

    handleImageError(event, index = 0) {
      if (this.imagePreviews.length > 0) {
        event.target.src = this.imagePreviews[index]
      } else {
        event.target.src = require('@/assets/images/image.svg')
      }
    },

    inputChange(type) {
      if (type == 'price_type') {
        this.form.price = null
        this.form.is_negotiable = 0
        this.form.no_bid_received = 1
      }

      if (type == 'is_rented') {
        this.form.current_rent_price = null
        this.form.remaining_month = null
      }

      if (type == 'region_id') {
        this.cities = []
        this.form.city_id = null
        this.districts = []
        this.form.district_id = null
        this.form.latitude = null
        this.form.longitude = null;
        (async () => {
          await this.getCities()
        })()
      }


      if (type == 'city_id') {
        this.districts = []
        this.form.district_id = null
        this.form.latitude = null
        this.form.longitude = null;
        (async () => {
          await this.getDistrict()
        })()
      }

      if (type == 'usage_id') {
        this.types = []

        this.form.type_id = null
        this.form.sub_type_id = null
        this.columns_real_estate_values = []

        this.sub_types = []
        this.amenities = []
        this.form.amenities = [];


        (async () => {
          await this.getTypesUsage()
        })()

      }
      if (type == 'type_id') {
        this.form.sub_type_id = null
        this.sub_types = []

        this.columns_real_estate_values = []

        this.amenities = []
        this.form.amenities = []
        this.form.info_fields = {}

        this.getSubTypes()
      }

      if (type == 'sub_type_id') {

        this.columns_real_estate_values = []

        this.amenities = []
        this.form.amenities = []
        this.form.info_fields = {}
        this.getSubTypesDynamic()
      }

    },

    async handleMapData(data) {
      try {
        if (data) {

          this.form.latitude = null
          this.form.longitude = null


          this.form.latitude = data.lat
          this.form.longitude = data.lng


        } else {
          console.error(`Geocoding failed`)
        }
      } catch (error) {
        console.error(error)
      }
    },


    getErrorTabLength(errors, array, v) {
      let errorLength = 0
      for (const el in errors) {
        if (array.includes(el) && this.formErrors[el].length > 0) {
          errorLength++
        }
      }
      this[v] = errorLength
    },
    loadData() {
      const params = {
        is_edit: true,
      }
      this.axios.get(`/${this.resource}/${this.resourceId}`, { params })
        .then(res => {

          const { data } = res.data
          this.form = {
            ...this.form,
            ...data,
            amenities: data.amenities?.map(el => el.amenity_id),
          }

          this.map_icon = data.map_icon_path
          this.images_arr = data.images_values ? data.images_values : []
          if (this.form['ad_type'] == 2) {
            this.disabled_fields = data.disabled_fields
            this.is_marker_disabled = true
          }

          this.video_arr = data.video ? [
            {
              name: this.form.video,
              path: this.form.video,
              type: null,
              url: this.form.video,
            },
          ] : []


          this.files_documents = data.documents ? data.documents : []

          if (this.images_arr.length > 0) {
            this.images_arr.forEach(el => {
              if (this.form.main_image == null) {
                this.form.main_image = el.original_name
              }
              this.images_files.push(el.url)
            })
          }

          if (this.video_arr.length > 0) {
            this.video_arr.forEach(el => {
              this.video_files.push(el.url)
            })
          }


          if (this.files_documents.length > 0) {
            this.files_documents.forEach(el => {
              this.documents_files.push({
                name: el.name,
                type: el.type,
                url: el.url,
                path: el.url,
              })
            })
          }
          delete this.form.documents_info
          delete this.form.type
          delete this.form.images_values


          if (this.form.city_id) {

            (async () => {
              await this.getCities()
            })();

            (async () => {
              await this.getDistrict()
            })()
          }

          (async () => {
            await this.getTypesUsageForEdit()
          })()


          this.form.is_rented = this.form.is_rented == false ? 0 : 1


          this.map_coordinate = [
            this.form.longitude,
            this.form.latitude,
          ]
          setTimeout(() => {
            this.isDataLoaded = true
          }, 2000)

        })
    },
    async getMemberTypes() {
      await this.axios.get(`${this.websiteUrl}/model-options/type-category?model=29`)
        .then(res => {
          this.member_types = res.data.data
        })
    },
    async getLists() {
      await this.axios.get(`${this.websiteUrl}/model-options/type-category?model=9`)
        .then(res => {
          this.the_sub_type_of_ads = res.data.data
        })
    },


    async getUsages() {
      await this.axios.get(`${this.websiteUrl}/model-options/usage`)
        .then(res => {
          this.usages = res.data.data
        })
    },
    isPDF(file) {
      const acceptedExtensions = ['pdf']
      return file.includes(acceptedExtensions)
    },
    watchFilesChanged(key, newVal) {
      if (key === 'images') {
        this.form[key] = []
        for (const el of newVal) {
          this.form[key].push(el.name)
        }
        if (this.isEdit && this.images_files.length > 0) {
          this.form[key] = this.images_files
        }
      } else if (key === 'video') {
        this.form[key] = null

        for (const el of newVal) {
          this.form[key] = el.name
        }

        if (this.isEdit) {
          this.form[key] = this.video_files ? this.video_files[0] : null
        }
      } else if (key === 'documents') {
        this.form[key] = []
        for (const el of newVal) {
          this.form[key].push(el.name)
        }
        if (this.isEdit && this.documents_files.length > 0) {
          this.form[key] = this.documents_files
        }
      } else {
        this.form[key] = []
        for (const el of newVal) {
          this.form[key].push(el)
        }
      }
    },
    selectTab(selected) {

      this.selectedTab = selected
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 100)
    },

    async getRegion() {
      await this.axios.get(`${websiteMainUrl()}/model-options/region`)
        .then(res => {
          this.regions = res.data.data
        })
    },

    getCities() {
      if (this.form.city_id === null) {
        this.cities = []
      }

      this.axios.get(`${websiteMainUrl()}/model-options/city?region_id=${this.form.region_id}`)
        .then(res => {
          this.cities = res.data.data
          return 1
        })
    },
    getDistrict() {

      if (this.form.district_id === null) {
        this.districts = []
      }

      this.axios.get(`/districts/list?city_id=${this.form.city_id}`)
        .then(res => {
          this.districts = res.data.data
        })
    },

    async getTypesUsage() {

      await this.axios.get(`${this.websiteUrl}/model-options/types?usage_id=${this.form.usage_id}`)
        .then(res => {
          this.types = res.data.data
        })
    },

    async getTypesUsageForEdit() {
      if (this.form.type_id) {
        var query = this.form.ad_type === 2 ? 'id=' + this.form.type_id : 'usage_id=' + this.form.usage_id
      } else {
        var query = 'usage_id=' + this.form.usage_id
      }

      await this.axios.get(`${this.websiteUrl}/model-options/types?${query}`)
        .then(res => {
          this.types = res.data.data

          this.getSubTypes(true)
        })
    },

    async getSubTypes(is_edit = false) {

      const item = this.types?.filter(el => el.id === this.form.type_id)

      if (item[0]?.id) {

        if (!item[0].has_children) {
          this.getDynamicFields(item, is_edit)
        }
        //
        if (item[0].parent == null && item[0].has_children && !is_edit) {
          await this.axios.get(`${this.websiteUrl}/model-options/types?usage_id=${this.form.usage_id}&parent_id=${item[0].id}`)
            .then(res => {
              this.form.sub_type_id = null
              this.sub_types = res.data.data
            })
        }

        if (item[0].parent == null && item[0].has_children && is_edit) {
          if (this.form.sub_type_id) {
            var query = this.form.ad_type === 2 ? 'id=' + this.form.sub_type_id : 'usage_id=' + this.form.usage_id + '&parent_id=' + item[0].id
          } else {
            var query = 'usage_id=' + this.form.usage_id + '&parent_id=' + item[0].id
          }


          await this.axios.get(`${this.websiteUrl}/model-options/types?${query}`)
            .then(res => {
              this.sub_types = res.data.data
              this.getSubTypesDynamic(is_edit)
            })
        }
      }
    },

    getDynamicFields(item, is_edit) {
      this.columns_real_estate_values = item[0]?.required_fields


      this.amenities = item[0]?.amenities
      //
      this.columns_real_estate_values = this.columns_real_estate_values.concat(item[0]?.not_required_fields)


      this.property_information_errors = this.original_property_information_errors


      this.columns_real_estate_values?.map((el) => {


        this.property_information_errors.push(el.key)

        if (!is_edit) {
          if (el.type === 'radio') {
            let val = this.form.info_fields[el.key]
            this.form.info_fields[el.key] = val ? val : el.options[0]?.value
          }
        }

        if (el.type === 'range') {
          let val = this.form.info_fields[el.key]

          if (el.is_two_sided == 1) {
            this.form.info_fields[el.key] = val ? val : [el.min, el.max]
          } else {
            if (val == null || val < el.min) {
              this.form.info_fields[el.key] = el.min
            } else {
              this.form.info_fields[el.key] = val ? val : el.min
            }
          }
        }

      })

    },

    getSubTypesDynamic(is_edit = false) {
      const sub_item = this.sub_types?.filter(el => el.id === this.form.sub_type_id)

      if (sub_item[0]?.id) {

        this.getDynamicFields(sub_item, is_edit)
        //

      }
    },

    handleDeleteImage(index, key) {

      this[key].splice(index, 1)

      if (this.isEdit && this.video_files.length > 0 && key === 'video_arr') {
        this.video_files.splice(index, 1)
      }
      if (this.isEdit && this.images_files.length > 0 && key === 'images_arr') {

        if (this.form.main_image === this.images_files[index]) {
          this.form.main_image = null
        }
        this.images_files.splice(index, 1)
      }
      if (this.isEdit && this.documents_files.length > 0 && key === 'files_documents') {
        this.documents_files.splice(index, 1)
      }


    },

    uploader(e, key) {

      this.isUploaderLoaded = true

      const { files } = e.target

      if (files.length > 0) {
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        this.imagePreviews = [] // Reset previews
        for (const el of files) {
          formData.append('files[]', el)

          // Create a preview for each file
          const reader = new FileReader()
          reader.onload = (event) => {
            this.imagePreviews.push(event.target.result)
          }
          reader.readAsDataURL(el)
        }
        formData.append('type', 'array')
        this.axios.post(`${this.websiteUrl}/file/uploader/action`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            if (!this.isEdit && key === 'images') {
              for (const el of response.data) {
                this.images_arr.push(el)
              }
            }

            if (this.isEdit && key === 'images') {
              for (const el of response.data) {
                this.images_files.push(el.name)
                this.images_arr.push(el)
              }
            }

            if (!this.isEdit && key === 'video') {
              this.video_arr = []


              for (const el of response.data) {
                this.video_arr.push(el)
              }
            }

            if (this.isEdit && key === 'video') {

              this.video_files = []
              this.video_arr = []

              for (const el of response.data) {
                this.video_files.push(el.name)
                this.video_arr.push(el)
              }
            }


            if (!this.isEdit && key === 'documents') {

              for (const el of response.data) {
                this.files_documents.push(el)
              }
            }

            if (this.isEdit && key === 'documents') {

              for (const el of response.data) {
                this.documents_files.push(el.name)
                this.files_documents.push(el)
              }
            }

            this.isUploaderLoaded = false
          }).catch(error => {
          this.isUploaderLoaded = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-start',
            props: {
              title: this.$t('Error'),
              icon: 'BellIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
      }
    },

    formatter2(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    validateLicense() {
      this.$refs.form.validate().then((isValid) => {
        if (isValid) {
          this.is_validator_loading = true
          this.axios.get(`real-estate/check-real-estate-authority?ad_license_number=${this.ad_license_number}&advertiser_id=${this.authorization_number}&id_type=${this.id_type}`)
            .then(res => {
              const data = res.data.data
              this.info_fields_label = data.info_fields_label
              this.disabled_fields = data.disabled_fields
              this.is_data_valid = true
              this.selectedTab = 'license_list'
              this.is_marker_disabled = true
              this.form.ad_type = 2
              this.form = { ...this.form, ...data.data }

              if (this.form.usage_id) {
                (async () => {
                  await this.getTypesUsageForEdit()
                })()
              }

              if (this.form.city_id) {
                this.inputChange('city_id')

                this.form.district_id = data.data.district_id
                this.form.longitude = data.data.longitude
                this.form.latitude = data.data.latitude

                this.map_coordinate = [
                  this.form.longitude,
                  this.form.latitude,
                ]
              }

              setTimeout(() => {
                this.form.amenities = data.data.amenities
                this.form.info_fields = { ...this.form.info_fields, ...data.info_fields }

                this.is_validator_loading = false

              }, 1500)

            }).catch(error => {
            this.info_fields_label = {}
            this.is_data_valid = false
            this.selectedTab = 'advertisement_details'
            this.form.ad_type = 1
            this.is_validator_loading = false

            this.$bvToast.toast(error.response.data.message, {
              title: this.$t('Error'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 3000,
              appendToast: true,
            })
          })
        } else {
          this.info_fields_label = {}
          this.is_data_valid = false
          this.selectedTab = 'advertisement_details'
          this.form.ad_type = 1
          this.is_validator_loading = false
          // Form is invalid, do something (e.g., display error message)
        }
      })

    },

    loadFormData() {
      let $form = _.cloneDeep(this.form)

      if (this.isEdit) {
        if (!($form.map_icon instanceof File)) {
          delete $form.map_icon
        }
      }

      $form = {
        ...$form,
      }
      return $form
    },
  },
}
</script>
<style lang='scss'>
.map-app {
  display: flex;
  height: 64vh;
  padding: 0;
  flex: 1;
}

#map-layout {
  flex: 1;
  display: flex;
  position: relative;
}

.hide-map {
  opacity: 0 !important;
  height: 0vh !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.hide-map-layer {
  position: absolute;
  background: transparent;
  width: 100%;
  height: 70vh;

  display: flex;
  z-index: 3;
}

.tab-layer {
  position: relative;
  z-index: 4;
}

.uploader-btn {
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 40px;
}

</style>
